<template>
  <div class="top-panel h-12 flex items-center px-4">
    <el-page-header
      class="page-header flex-shrink-0"
      :content="$route.meta?.title"
      @back="$router.back"
    />
    <div class="flex-grow ml-4 flex items-center justify-end">
      <span class="mark">{{chromeVersionTip}}</span>
      &nbsp;&nbsp;
      <el-avatar v-if="userInfo" :src="userInfo.image" @click="$router.push({name: 'Index'})" />
    </div>
  </div>
</template>

<script lang="ts">
import { userStore } from '@/store/modules/user'
import { isProd } from '@/utils'
import { computed, defineComponent } from 'vue'
import { getChromeVersion } from '@/utils'

export default defineComponent({
  setup() {
    const userInfo = computed(() => userStore.getUserInfo)

    function logout() {
      userStore.dispatchLogout()
    }
    const chromeVersion = getChromeVersion()
    const chromeVersionTip =
      chromeVersion < 76 ? `请尽量使用76版本以上的谷歌浏览器, 当前版本:${chromeVersion || '非Chrome'}` : ''
    return {
      chromeVersionTip,
      userInfo,
      logout,
      isProd
    }
  }
})
</script>

<style lang="scss" scoped>
.logout {
  color: #f33;
  font-weight: 600;
}
.setting-panel {
  padding-left: 12px;
  width: 100%;
}
.top-panel {
  border-bottom: 1px solid #eee;
  color: rgba(0, 0, 0, 0.65);
  min-height: 48px;
  :deep {
    .el-page-header__content {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
</style>
