<template>
  <template v-if="route.meta && !route.meta.removeFromSideBar">
    <el-submenu v-if="route.children && route.children.length" :index="route.name">
      <template #title>
        <i v-if="route.meta && route.meta.icon" :class="`el-icon-${route.meta.icon}`"></i>
        <span v-if="route.meta && route.meta.title">{{route.meta.title}}</span>
        <span v-else>{{route.name}}</span>
      </template>
      <SideBarItem
        v-for="_route in route.children"
        :key="_route.name"
        :route="_route"
        :path="joinPath(path, _route.path)"
        :shouldHideMenuMap="shouldHideMenuMap"
      />
    </el-submenu>
    <template v-else-if="!shouldHideMenuMap[path] || show_banned_menu">
      <el-menu-item :disabled="shouldHideMenuMap[path] && !allow_auth_free" :index="route.name">
        <i v-if="route.meta && route.meta.icon" :class="`el-icon-${route.meta.icon}`"></i>
        <span v-if="route.meta && route.meta.title">{{route.meta.title}}</span>
        <span v-else>{{route.name}}</span>
        <i v-if="shouldHideMenuMap[path]" class="el-icon-warning"></i>
      </el-menu-item>
    </template>
  </template>
</template>
<script>
import { isProd } from '@/utils'
// import store from '@/store'

/**
 * @description jsx组件存在偶发情况下v-slot={}报错的问题
 */

export default {
  name: 'SideBarItem',
  props: {
    route: Object,
    shouldHideMenuMap: Object,
    path: { type: String, default: '' }
  },
  computed: {
    show_banned_menu() {
      return this.$store.state.show_banned_menu
    },
    allow_auth_free() {
      return !isProd && this.$store.state.allow_auth_free
    }
  },
  methods: {
    joinPath(p1, p2) {
      return `${p1[0] === '/' ? p1 : '/' + p1}${p2[0] === '/' ? p2 : '/' + p2}`
    }
  }
}
</script>
