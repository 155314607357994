import type { Router, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import { userStore } from '@/store/modules/user'
import { isProd } from '@/utils'
import eventHub from '@/utils/event_hub'
import { ElMessage, ElLoading } from 'element-plus'

const AUTH_INFO = {
  POOL: {},
  AUTH: {},
  inited: false
}
window.AUTH_INFO = AUTH_INFO
/**
 * @description 生成一次性权限map 便于快速check
 * @param {*} poolRoutes
 * @param {*} authRoutes
 */
function generateCheckMap(poolRoutes, authRoutes) {
  const POOL = {}
  const AUTH = {}
  poolRoutes.forEach(v => {
    if (v.is_deleted === false) {
      POOL[v.route] = true
    }
  })
  authRoutes.forEach(v => {
    AUTH[v.route] = true
  })
  AUTH_INFO.POOL = POOL
  AUTH_INFO.AUTH = AUTH
  AUTH_INFO.inited = true
  const shouldHideMenuMap = {
    '': false,
    undefined: false,
    null: false
  }
  Object.keys(AUTH_INFO.POOL).forEach(path => {
    if (!AUTH_INFO.AUTH[path]) {
      shouldHideMenuMap[path] = true
    }
  })
  // eslint-disable-next-line vue/custom-event-name-casing
  eventHub.$emit('update_auth_menu', shouldHideMenuMap)
}

function handleCheckAuth(to, from, next) {
  if (!userStore.getToken) {
    next()
    return
  }
  const { route_pool, route_auth, route_init } = store.state
  const target = to.matched[to.matched.length - 1].path
  if (!route_init) {
    console.log('Auth initializing...')
    const loading = ElLoading.service({
      lock: true,
      text: 'Auth initializing...',
      spinner: 'el-icon-loading',
      background: 'rgba(255, 255, 255, 0.5)'
    })
    store
      .dispatch('init_role_auth')
      .then(authres => {
        loading.close()
        const [POOL, AUTH] = authres
        store.commit('update_common', {
          route_init: true
        })
        if (!AUTH_INFO.inited) generateCheckMap(POOL, AUTH)
        if (AUTH_INFO.POOL[target] === true && !AUTH_INFO.AUTH[target]) {
          next({ name: 'NotAuthed', query: { target } })
        } else if (!AUTH_INFO.POOL[target] && to.meta.needAuth === true) {
          next({ name: 'NotAuthed', query: { target } })
        } else {
          next()
        }
      })
      .catch(err => {
        loading.close()
        ElMessage.error('未能获取用户权限信息，请刷新重试')
        next({ name: 'NotFound' })
      })
    return
  }
  if (!AUTH_INFO.inited) generateCheckMap(route_pool, route_auth)
  if (AUTH_INFO.POOL[target] === true && !AUTH_INFO.AUTH[target]) {
    next({ name: 'NotAuthed', query: { target } })
  } else if (!AUTH_INFO.POOL[target] && to.meta.needAuth === true) {
    next({ name: 'NotAuthed', query: { target } })
  } else {
    next()
  }
}

export default function createProgressGuard(router: Router) {
  if (!isProd && store.state.allow_auth_free) return
  router.beforeEach(handleCheckAuth)
}