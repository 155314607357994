import type { App, Component } from 'vue'
import * as globalComponents from './globalComponents'
import * as globalProperties from './globalProperties'

export function setupPlugins(app: App) {
  // set global components
  Object.keys(globalComponents.default).forEach((componentName) => {
    const component = globalComponents.default[componentName]
    component.name && app.component(component.name, component)
  })
  // set global properties
  Object.entries(globalProperties).forEach(([name, val]) => {
    app.config.globalProperties[`$${name}`] = val
    app.provide(name, val)
  })
}
