// HTTP status code !== 200
export class HttpErr extends Error {
  readonly status: number
  readonly url: string | undefined
  constructor(message: string, { status, url }: { status: number; url: string | undefined }) {
    super(message)
    this.status = status
    this.url = url
  }

  get errorString() {
    return `[${this.status}] : ${this.url}`
  }
}

export class HttpUnknownErr extends Error {}

export class HttpFailedConnectionErr extends Error {}
