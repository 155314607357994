import baoer from '@/http/baoer'

export * from './auth-setting'

// baoer搜话题
export const searchSubject = params => {
  return baoer.get('/api/v6/admin/subject/get_subjects', { params })
}
// baoer搜板块
export const baoerSearchPlate = params => baoer.get('/xgbadmin/plate/list/lite', { params })

/**
 * 获取直播间
 * @param {Object} params
 * @param page 当前页
 * @param limit 每页大小
 * @param platform xgb
 * @param resource_type 大盘直播间：streamer，大V直播间：streamer_influencer，主题直播间：streamer_theme,视频直播间：streamer_newsroom
 * @param q 模糊查询
 */
export const getLiveRoomList = params => baoer.get('/api/v6/admin/streamer/streamer', { params })
// 后台设置推荐主题直播间
export const updateRecommendThemesLiveRoom = ids => {
  return baoer.post('/api/v6/admin/streamer_tab/set_recommend_themes', { ids })
}
// admin获取推荐主题直播
export const getRecommendThemesLiveRoom = () => baoer.get('/api/v6/admin/streamer_tab/get_recommend_themes')
// 后台热门铁粉群设置
export const updateHotFansLiveRoom = items => {
  return baoer.post('/api/v6/admin/streamer_tab/set_hot_influencers', { items })
}
// 后台热门铁粉群列表
export const getHotFans = () => baoer.get('/api/v6/admin/streamer_tab/get_hot_influencers')
// 后台设置推广大V直播间
export const updateInfluencerLiveRoom = id => {
  return baoer.post('/api/v6/admin/streamer_tab/set_promote_influencer', { id })
}

// 发现页顶部滑块配置
export const fetchDiscoverTabs = (params) => baoer.get('/api/v6/admin/find/tab', { params })
export const addDiscoverTab = (body) => baoer.post('/api/v6/admin/find/tab', body)
export const updateDiscoverTab = (body) => baoer.put('/api/v6/admin/find/tab', body)

// 获取 kvconfig by-key
export const getKVConfigContent = key => baoer.get(`/api/v6/kvconfig/key/${key}`)
// 获取 kvconfig 列表
export const getKVConfigList = params => baoer.get('/api/admin/kvconfig', { params })
// 创建 kvconfig
export const createKVConfig = data => baoer.post('/api/admin/kvconfig', data)
// 更新 kvconfig
export const updateKVConfig = (id, data) => baoer.put(`/api/admin/kvconfig/id/${id}`, data)
// 获取 kvconfig 详情
export const getKVConfig = id => baoer.get(`/api/admin/kvconfig/id/${id}`)
// 删除 kvconfig
export const deleteKVConfig = id => baoer.delete(`/api/admin/kvconfig/id/${id}`)

// 获取软文模板列表
export const getArticleTemplateList = params => baoer.get('/api/v6/admin/article_template/list', { params })
// 获取单个软文模板
export const getArticleTemplate = id => baoer.get(`/api/v6/admin/article_template/${id}`)
// 创建软文模板
export const createArticleTemplate = data => baoer.post('/api/v6/admin/article_template/create', data)
// 更新软文模板
export const updateArticleTemplate = (id, data) => baoer.put(`/api/v6/admin/article_template/${id}`, data)
// 删除软文模板
export const deleteArticleTemplate = id => baoer.delete(`/api/v6/admin/article_template/${id}`)
// 恢复软文模板
export const recoverArticleTemplate = id => baoer.post(`/api/v6/admin/article_template/${id}`)
// 搜索软文模板
export const searchArticleTemplate = params => baoer.get('/api/v6/admin/article_template/search', { params })

// 获取 iOS 隐藏证券交易状态
export const getIOSHideTrading = () => baoer.get('/xgbadmin/v2/conf/iosQuotePaymentHidden')
// 修改 iOS 隐藏证券交易状态
export const updateIOSHideTrading = ({ hidden }) => baoer.put(`/xgbadmin/v2/conf/iosQuotePaymentHidden?hidden=${hidden}`)

// route-icon
export const fetchAppIcon = params => {
  return baoer.get('/api/v6/admin/misc/feature_app', { params })
}
export const addAppIcon = body => {
  return baoer.post('/api/v6/admin/misc/feature_app', body)
}
export const updateAppIcon = body => {
  return baoer.put('/api/v6/admin/misc/feature_app', body)
}
export const deleteAppIcon = id => {
  return baoer.delete(`/api/v6/admin/misc/feature_app/${id}`)
}

// 会员卡相关
export const fetchVipCardList = params => {
  return baoer.get('/api/v6/admin/card', { params })
}
export const createVipCard = body => {
  return baoer.post('/api/v6/admin/card', body)
}
export const updateVipCard = body => {
  return baoer.put('/api/v6/admin/card', body)
}

// 根据resource_id拿商品信息
export const fetchProductByResource = (body) => {
  return baoer.post('/api/v6/admin/product/resource', body)
}
