<template>
  <div class="flex w-full">
    <SideBar class="flex-shrink-0 overflow-x-hidden h-screen overflow-y-auto" />
    <div class="flex-grow">
      <TopPanel />
      <div class="px-4 page-wrap">
        <router-view v-slot="{ Component }">
          <keep-alive v-if="$route.meta.keepAlive">
            <component :is="Component" />
          </keep-alive>
          <component v-else :is="Component" />
        </router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SideBar from './SideBar.vue'
import TopPanel from './topPanel/index.vue'

export default defineComponent({
  components: {
    SideBar,
    TopPanel
  }
})
</script>
<style lang="scss">
.page-wrap {
  // overflow-x: hidden;
}
</style>