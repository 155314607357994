import type { RouteRecordRaw } from 'vue-router'
import Layout from '@/Layout/index.vue'

const record: RouteRecordRaw = {
  name: 'AdminSettings',
  path: '/admin-settings',
  component: Layout,
  meta: {
    title: '后台配置',
    icon: 'setting'
  },
  children: [
    {
      path: 'staff-manage',
      name: 'StaffManage',
      component: () => import(/* webpackChunkName: "staff-manage" */ '@/pages/admin-settings/staff-manage.vue'),
      meta: {
        title: '员工管理',
        icon: 'user-solid',
        needAuth: true
      }
    },
    {
      path: 'auth-manage',
      name: 'AuthManage',
      component: () => import(/* webpackChunkName: "auth-manage" */ '@/pages/admin-settings/auth-manage.vue'),
      meta: {
        title: '权限管理',
        icon: 's-check',
        needAuth: true
      }
    }
  ]
}

export default record
