
import { defineComponent, ref } from 'vue'
import { routes } from '@/router'
import SideBarItem from './SideBar/SideBarItem.vue'
import { useRoute, useRouter } from 'vue-router'
import eventHub from '@/utils/event_hub'

export default defineComponent({
  components: {
    SideBarItem
  },
  setup() {
    const collapse = ref(false)
    const thisRoute = useRoute()
    const thisRouter = useRouter()
    const shouldHideMenuMap = ref({})

    function toogleCollapse() {
      collapse.value = !collapse.value
    }

    function selectRouteHandler(index: string) {
      if (thisRoute.name === index) return
      thisRouter.push({ name: index })
    }
    return {
      collapse,
      toogleCollapse,
      selectRouteHandler,
      routes,
      shouldHideMenuMap
    }
  },
  mounted() {
    eventHub.$on(
      'update_auth_menu',
      (shouldHideMenuMap) => {
        this.shouldHideMenuMap = shouldHideMenuMap
        window.shouldHideMenuMap = shouldHideMenuMap
      },
      'side_bar',
      true
    )
  }
})
