
import { userStore } from '@/store/modules/user'
import { isProd } from '@/utils'
import { computed, defineComponent } from 'vue'
import { getChromeVersion } from '@/utils'

export default defineComponent({
  setup() {
    const userInfo = computed(() => userStore.getUserInfo)

    function logout() {
      userStore.dispatchLogout()
    }
    const chromeVersion = getChromeVersion()
    const chromeVersionTip =
      chromeVersion < 76 ? `请尽量使用76版本以上的谷歌浏览器, 当前版本:${chromeVersion || '非Chrome'}` : ''
    return {
      chromeVersionTip,
      userInfo,
      logout,
      isProd
    }
  }
})
