import type { ConfigMap } from './index'

const config: ConfigMap = {
  env: 'fit',
  apiBase: {
    BASE_API: 'https://api-fit.xuangubao.cn',
    DDC_API: 'https://api-ddc-wscn-sit.xuangubao.cn',
    STARK_API: 'https://stark-api-fit.xuangubao.cn',
    QINIU_DOMAIN: 'https://image.xuangubao.cn',
    JIRA_API: '/jira',
    WSCN_API: 'https://api-wscn-sit.xuangubao.cn',
    BAOER_API: 'https://baoer-api-sit.xuangubao.cn',
    FLASH_API: 'https://flash-api.xuangubao.cn'
  },
  siteDomain: {
    PC_DOMAIN: 'https://clay-fit.xgb.cn',
    M_DOMAIN: 'https://m-baoer-sit.xgb.cn',
    ADMIN_DOMAIN: 'https://666-fit.xuangubao.cn',
    JIRA_DOMAIN: 'http://139.224.26.114:8080'
  }
}

export default config
