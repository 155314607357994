<template>
  <div class="component-common-card">
    CommonCard
    <input style="color: #e50" type="text" :value="message" @input="(ev) => $emit('update:message', ev.target.value)" />
    <input
      style="color: #e50"
      type="text"
      :value="modelValue"
      @input="(ev) => $emit('update:modelValue', ev.target.value)"
    />
  </div>
</template>
<script>
export default {
  name: 'CommonCard',
  props: {
    modelValue: {
      type: [String, Object],
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    obj: {
      type: Object,
      default() {
        return {
          a: 0,
          b: 9
        }
      }
    }
  },
  components: {},
  data() {
    return {
      list: [1, 2, 3]
    }
  },
  setup(props) {
    console.log(props.obj.a)
    return {}
  }
}
</script>
<style lang="scss" scoped>
.component-common-card {
  position: relative;
}
</style>
