<template>
  <div class="side-bar-wrap" :class="{ collapse }">
    <div class="side-bar-collapse text-center cursor-pointer" :style="{ lineHeight: '48px' }">
      <span class="font-bold" v-if="collapse" @click="toogleCollapse">
        <i class="el-icon-d-arrow-right font-bold"></i>
      </span>
      <span class="font-bold" v-else @click="toogleCollapse">
        <span>选股宝✨ &nbsp;&nbsp;</span>
        <i class="el-icon-d-arrow-left font-bold"></i>
      </span>
    </div>
    <el-menu
      class="side-bar-menu"
      background-color="#072a46"
      active-text-color="#ffd04b"
      text-color="#ffffff"
      unique-opened
      :collapse="collapse"
      :collapse-transition="false"
      :default-active="$route.name"
      @select="selectRouteHandler"
    >
      <SideBarItem
        v-for="item in routes"
        :key="item.path"
        :route="item"
        :path="item.path"
        :shouldHideMenuMap="shouldHideMenuMap"
      />
    </el-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { routes } from '@/router'
import SideBarItem from './SideBar/SideBarItem.vue'
import { useRoute, useRouter } from 'vue-router'
import eventHub from '@/utils/event_hub'

export default defineComponent({
  components: {
    SideBarItem
  },
  setup() {
    const collapse = ref(false)
    const thisRoute = useRoute()
    const thisRouter = useRouter()
    const shouldHideMenuMap = ref({})

    function toogleCollapse() {
      collapse.value = !collapse.value
    }

    function selectRouteHandler(index: string) {
      if (thisRoute.name === index) return
      thisRouter.push({ name: index })
    }
    return {
      collapse,
      toogleCollapse,
      selectRouteHandler,
      routes,
      shouldHideMenuMap
    }
  },
  mounted() {
    eventHub.$on(
      'update_auth_menu',
      (shouldHideMenuMap) => {
        this.shouldHideMenuMap = shouldHideMenuMap
        window.shouldHideMenuMap = shouldHideMenuMap
      },
      'side_bar',
      true
    )
  }
})
</script>

<style lang="scss" scoped>
.side-bar-wrap {
  background: $main-grey;
  flex-basis: 210px;
  left: 0;
  position: sticky;
  top: 0;
  transition: all 0.3s;
  z-index: 101;
  &.collapse {
    flex-basis: 64px;
  }
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}
.el-menu {
  border-right-width: 0;
}
.side-bar-menu:not(.el-menu--collapse) {
  width: 210px;
}
.side-bar-collapse {
  color: $main-goden;
}
</style>
