import axios from 'axios'
import type { App, InjectionKey } from 'vue'
import { createStore, Store, useStore as useStoreOrigin } from 'vuex'
import { config } from 'vuex-module-decorators'
import { isProd, intervalTimer } from '@/utils'
import { ElNotification } from 'element-plus'
import {
  getAdminAuthList,
  getMyPermissionList
} from '@/domains/base-baoer'
import witsModule, { WitsModuleState } from './modules/wits'
import errorModule, { ErrorModuleState } from './modules/error'

config.rawError = true

type RouteItem = {
  id: number
  is_deleted: boolean
  name: string
  route: string
  [key: string]: {}
}

export interface RootState {
  app_name: string
  app_version: string
  route_pool: RouteItem[]
  route_auth: RouteItem[]
  route_init: boolean
  show_banned_menu: boolean
  allow_auth_free: boolean
  [key: string]: {}
}

export interface IntegrityState extends RootState {
  wits: WitsModuleState,
  error: ErrorModuleState
}

const app_version = (document.querySelector('meta[name=version]') as HTMLMetaElement).content
const show_banned_menu = !!localStorage.getItem('XGB_ADMIN_SHOW_BANNED_MENU') || false
const allow_auth_free = !!localStorage.getItem('XGB_ADMIN_ALLOW_AUTH_FREE') || false
window.app_version = app_version
const state = {
  app_name: 'xgb-admin',
  app_version,
  route_pool: [],
  route_auth: [],
  route_init: false,
  show_banned_menu,
  allow_auth_free
}

const mutations = {
  update_common(state, payload: Partial<RootState>) {
    Object.assign(state, payload)
  },
  INIT_AUTH_ROUTE(state, data) {
    state.route_auth = data
  },
  INIT_POOL_ROUTE(state, data) {
    state.route_pool = data
  }
}

function wideRange(treeData) {
  if (!treeData || !treeData.length) return []
  let stack: any[] = []
  const nodes: any[] = []
  for (let i = 0; i < treeData.length; i++) {
    stack.push(treeData[i])
  }
  while (stack.length) {
    const item = stack.shift() as any
    const { id, name, route, is_deleted } = item
    nodes.push({
      id,
      name,
      route,
      is_deleted
    })
    if (item.items && item.items.length) {
      stack = stack.concat(item.items)
    }
  }
  return nodes
}

const actions = {
  init_role_auth({ commit }) {
    const FETCH_POOL = getAdminAuthList({}).then((data) => {
      if (data.items && data.items.length) {
        const temp = wideRange(data.items)
        commit('INIT_POOL_ROUTE', temp)
        return temp
      } else {
        commit('INIT_POOL_ROUTE', [])
        return []
      }
    })
    const FETCH_AUTH = getMyPermissionList().then((data) => {
      if ((data as any).items && (data as any).items.length) {
        const temp = wideRange((data as any).items)
        commit('INIT_AUTH_ROUTE', temp)
        return temp
      } else {
        commit('INIT_AUTH_ROUTE', [])
        return []
      }
    })
    return Promise.all([FETCH_POOL, FETCH_AUTH])
  },
  check_version({ state, commit }) {
    axios.get(location.origin).then(res => {
      if (!res.data) return
      const match = res.data.match(/<meta name="version" content="([^>]+)"/)
      const version = (match && match[1]) || ''
      console.log('check_version', version)
      if (version && version !== state.app_version) {
        commit('update_common', { app_version: version })
        ElNotification({
          title: '有新的版本可用',
          duration: 0,
          showClose: true,
          type: 'success',
          message: '点击立即更新',
          onClick() {
            location.reload()
          }
        })
      }
    })
  }
}

const store = createStore<RootState>({
  strict: !isProd,
  state,
  mutations,
  actions,
  modules: {
    wits: witsModule,
    error: errorModule
  },
})

if (process.env.VUE_APP_ENV !== 'prod') window.store = store

export const key: InjectionKey<Store<IntegrityState>> = Symbol('vue-store')

export function useStore<T = IntegrityState>() {
  return useStoreOrigin<T>(key)
}

export function setupStore(app: App) {
  app.use(store, key)
  bootstrap(store)
}

export default store

function bootstrap(store: Store<RootState>) {
  isProd && intervalTimer(() => {
    store.dispatch('check_version')
  }, 60000)
}