import Cookies from 'js-cookie'
import { getEnv } from './index'
window.Cookies = Cookies
export const getTokenKey = () => `XGB_ALCHEMIST_${getEnv().toLocaleUpperCase()}_TOKEN`

export const setCookieToken: (string: string) => void = (token) => {
  if (process.env.NODE_ENV === 'production') {
    Cookies.set(getTokenKey(), token, { domain: '.xuangubao.cn', expires: 10 })
  } else {
    Cookies.set(getTokenKey(), token, { expires: 10 })
  }
}

export const getCookieToken: () => string = () => {
  try {
    const token = Cookies.get(getTokenKey())
    return token || ''
  } catch (error) {
    return ''
  }
}
