import baoer from '@/http/baoer'

type RouteMan = {
  display_name: string
  email: string
  image: string
  is_deleted: boolean
  user_id: number | string
}

type RouteItem = {
  depth: number
  id: number
  is_deleted: boolean
  items: RouteItem[]
  managers: RouteMan[]
  name: string
  order: number
  path: string
  route: string
  user_count: number
}

// 员工池操作
export const getAdminStaffList = (params) => {
  return baoer.get('/api/admin/internal_permission/user/list', { params })
}
export const addAdminStaff = body => {
  return baoer.put(`/api/admin/internal_permission/user/${body.user_id}/add`)
}
export const deleteAdminStaff = body => {
  return baoer.put(
    `/api/admin/internal_permission/user/${body.user_id}/delete`
  )
}
export const recoverAdminStaff = body => {
  return baoer.put(
    `/api/admin/internal_permission/user/${body.user_id}/recover`
  )
}

// 权限池
export const getAdminAuthList = (params): Promise<{ items: RouteItem[] }> => {
  return baoer.get('/api/admin/internal_permission/permission/list', {
    params
  })
}
// 新增权限到权限池
export const addAdminAuth = body => {
  return baoer.put('/api/admin/internal_permission/permission/save', body)
}
// 更新权限池树结构
export const updateStaffAuthTree = body => {
  return baoer.put(
    '/api/admin/internal_permission/permission/save_tree',
    body
  )
}
// 更改某个权限授权池
export const updateSomeAuthStaffList = body => {
  return baoer.put(
    '/api/admin/internal_permission/permission/set_user',
    body
  )
}

// 我的权限列表
export const getMyPermissionList = () => {
  return baoer.get('/api/admin/internal_permission/user/my_permissions')
}
// 某个用户的权限列表
export const getPermissionListByUserId = user_id => {
  return baoer.get(
    `/api/admin/internal_permission/user/permission/${user_id}`
  )
}
// 更新用户权限(列表)
export const updateStaffAuth = body => {
  return baoer.put(
    `/api/admin/internal_permission/user/permission/${body.user_id}`,
    body
  )
}