import type { ConfigMap } from './index'

const config: ConfigMap = {
  env: 'prod',
  apiBase: {
    BASE_API: 'https://api.xuangubao.cn',
    DDC_API: 'https://api-ddc-wscn.xuangubao.cn',
    STARK_API: 'https://stark-api.xuangubao.cn',
    QINIU_DOMAIN: 'https://image.xuangubao.cn',
    JIRA_API: '/jira',
    WSCN_API: 'https://api-wscn.xuangubao.cn',
    BAOER_API: 'https://baoer-api.xuangubao.cn',
    FLASH_API: 'https://flash-api.xuangubao.cn'
  },
  siteDomain: {
    PC_DOMAIN: 'https://xgb.cn',
    M_DOMAIN: 'https://m.xgb.cn',
    ADMIN_DOMAIN: 'https://666.xuangubao.cn',
    JIRA_DOMAIN: 'http://139.224.26.114:8080'
  }
}

export default config
