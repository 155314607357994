import type { RouteRecordRaw } from 'vue-router'
import Layout from '@/Layout/index.vue'
import { isProd } from '@/utils'

// cloudy cloudy-and-sunny light-rain lightning heavy-rain
const record: RouteRecordRaw = {
  name: 'Operation',
  path: '/operation',
  component: Layout,
  meta: {
    title: '运营相关',
    icon: 's-marketing'
  },
  children: [
    {
      path: 'vip-card',
      name: 'VipCard',
      component: () => import('@/pages/operation/vip-card/index.vue'),
      meta: {
        title: '会员卡',
        icon: 's-ticket',
        needAuth: isProd && true
      }
    },
    // {
    //   path: 'auth-manage',
    //   name: 'AuthManage',
    //   component: () => import(/* webpackChunkName: "auth-manage" */ '@/pages/admin-settings/auth-manage.vue'),
    //   meta: {
    //     title: '权限管理',
    //     icon: 's-check',
    //     needAuth: true
    //   }
    // }
  ]
}

export default record
