import type { AxiosRequestConfig } from 'axios'
import config from '@/config'

export default function (requestConfig: AxiosRequestConfig): AxiosRequestConfig {
  if (config.env !== 'local') {
    return requestConfig
  }
  try {
    const { method, baseURL, url, params, data } = requestConfig
    let payload = method === 'get' ? params : data
    payload = payload || {}
    // console.log(`${method && method.toUpperCase()} ${baseURL}${url}`)
    // Object.keys(payload).length && console.log(JSON.stringify(payload, null, 4))
    return requestConfig
  } catch (error) {
    return requestConfig
  }
}
