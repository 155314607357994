import type { App, Component } from 'vue'
export interface ComponentsMap {
  [string: string]: Component
}

function getComponent() {
  return require.context('./', true, /\.vue$/)
}

const componentsMap: ComponentsMap = {}

const requireComponents = getComponent()
requireComponents.keys().forEach((fileSrc: string) => {
  const component = requireComponents(fileSrc).default
  componentsMap[component.name] = component
})

export default componentsMap
