import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { isProd } from '@/utils'
import Layout from '@/Layout/index.vue'
// import witsauth from './routes/wits-auth'
import operation from './routes/operation'
import requirements from './routes/requirements'
import settings from './routes/settings'
import stats from './routes/stats'
import admin_settings from './routes/admin-settings'
import crateGuarde from './guards'

// const modulesFiles = require.context('./routes', true, /\.ts$/)
// const modules: RouteRecordRaw[] = modulesFiles
//   .keys()
//   .reduce((modules, modulePath) => [...modules, modulesFiles(modulePath).default], [] as RouteRecordRaw[])

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    redirect: '/index',
    meta: {
      removeFromSideBar: true,
      title: '首页'
    },
    children: [
      {
        name: 'Index',
        path: '/index',
        meta: {
          removeFromSideBar: true,
          title: '主页'
        },
        component: () => import(/* webpackChunkName: "index" */ '@/pages/index.vue')
      },
      {
        name: 'NotAuthed',
        path: '/not-authed',
        meta: {
          removeFromSideBar: true,
          title: '403'
        },
        component: () => import(/* webpackChunkName: "not-authed" */ '@/pages/404.vue')
      }
    ]
  },
  // witsauth,
  operation,
  requirements,
  stats,
  settings,
  admin_settings,
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/pages/login.vue'),
    meta: {
      removeFromSideBar: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '@/pages/404.vue'),
    meta: {
      removeFromSideBar: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

if (!isProd) window.router = router

export default router

export function setupRouter(app: App) {
  app.use(router)
  crateGuarde(router)
}
