
import uniqBy from 'lodash/uniqBy'
import { PropType, computed, ref, getCurrentInstance, ComponentInternalInstance } from 'vue'

/**
 * @description 需要搜索的单选框
 */

export default {
  name: 'CommonSelector',
  props: {
    modelValue: {
      type: [String, Number, Object] as PropType<string | number | {}>
    },
    searchMethod: {
      type: Function,
      required: true
    },
    size: {
      type: String,
      default: 'small'
    },
    placeholder: {
      type: String,
      default: '关键词搜索'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    options: {
      type: [Array, Object] as PropType<[] | {}>
    },
    keymap: {
      type: Object as PropType<{ label: string; value: string }>,
      default: () => {
        return {
          label: 'label',
          value: 'value'
        }
      }
    }
  },
  setup(props, ctx) {
    const { proxy } = getCurrentInstance() as ComponentInternalInstance
    const value = computed(() => {
      if (typeof props.modelValue === 'object') return props.modelValue[props.keymap.value]
      return props.modelValue
    })
    const loading = ref<boolean>(false)
    const refRemoteOptions = ref<any[]>([])
    const defaultOptions: any[] = []
    if (typeof props.modelValue === 'object') {
      defaultOptions.push(props.modelValue)
    }
    if (props.options) {
      if (Array.isArray(props.options)) defaultOptions.push(...props.options)
      if (Object.prototype.toString.call(props.options) === '[object Object]') defaultOptions.push(props.options)
    }
    const refRenderOptions = computed(() => {
      return uniqBy([...defaultOptions, ...refRemoteOptions.value], props.keymap.value)
    })
    const handleSearchOptions = (query: string) => {
      props.searchMethod(query, { options: refRemoteOptions, loading })
    }
    const onChange = (ev: any) => {
      let res = ev
      if (typeof props.modelValue === 'object') {
        res = refRenderOptions.value.find((v) => v[props.keymap.value] === ev)
      }
      ctx.emit('update:modelValue', res)
    }
    if (process.env.NODE_ENV === 'development') window.vm = proxy
    return { value, refRenderOptions, handleSearchOptions, loading, onChange }
  }
}
