import axios from 'axios'
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { emptyFun } from '@/utils'
import { HttpErr, HttpFailedConnectionErr, HttpUnknownErr } from './err'
import errorLogger from './errorLogger'
import requestLogger from './requestLogger'

function handleHttpError(error: AxiosError) {
  const { url } = error.config
  if (error.response) {
    // 服务器有返回
    const { status } = error.response
    throw new HttpErr(error.message, {
      status,
      url
    })
  } else {
    // 未连接到服务器
    throw new HttpFailedConnectionErr(`failed to connect to ${url}.`)
  }
}

function validateResponse(response: AxiosResponse): AxiosResponse {
  if (response.data) return response
  throw new HttpUnknownErr()
}

export default class AxiosClient {
  readonly client: AxiosInstance

  readonly getUri: (config?: AxiosRequestConfig) => string
  readonly request: <T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig) => Promise<R>
  readonly get: <T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig) => Promise<R>
  readonly delete: <T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig) => Promise<R>
  readonly head: <T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig) => Promise<R>
  readonly options: <T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig) => Promise<R>
  readonly post: <T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<R>
  readonly put: <T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<R>
  readonly patch: <T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<R>

  constructor({ timeout = 30 * 1000, ...rest }: AxiosRequestConfig) {
    this.client = axios.create({
      timeout,
      ...rest
    })

    this.getUri = this.client.getUri.bind(this.client)
    this.request = this.client.request.bind(this.client)
    this.get = this.client.get.bind(this.client)
    this.delete = this.client.delete.bind(this.client)
    this.head = this.client.head.bind(this.client)
    this.options = this.client.options.bind(this.client)
    this.post = this.client.post.bind(this.client)
    this.put = this.client.put.bind(this.client)
    this.patch = this.client.patch.bind(this.client)

    this._interceptRequest()
    this._interceptResponse()
  }

  _interceptRequest() {
    this.client.interceptors.request.use(requestLogger, Promise.reject)
  }
  _interceptResponse() {
    this.client.interceptors.response.use(emptyFun, handleHttpError)
    this.client.interceptors.response.use(validateResponse, errorLogger)
  }
}
