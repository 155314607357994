
import { userStore } from '@/store/modules/user'
import { getQiniuToken } from '@/domains/base-xgb'

export default {
  mounted() {
    if (userStore.getToken) {
      getQiniuToken().then((res: any) => {
        if (!res || !res.Token) return
        localStorage.setItem('qnToken', res.Token)
      })
    }
  }
}
