<template>
  <router-view></router-view>
</template>
<script lang="ts">
import { userStore } from '@/store/modules/user'
import { getQiniuToken } from '@/domains/base-xgb'

export default {
  mounted() {
    if (userStore.getToken) {
      getQiniuToken().then((res: any) => {
        if (!res || !res.Token) return
        localStorage.setItem('qnToken', res.Token)
      })
    }
  }
}
</script>

<style lang="scss">
.nav {
  padding: 30px;
  a {
    color: #2c3e50;
    font-weight: bold;
  }
  a .router-link-exact-active {
    color: #42b983;
  }
}
</style>
