import type { RouteRecordRaw } from 'vue-router'
import Layout from '@/Layout/index.vue'

const record: RouteRecordRaw = {
  path: '/requirements',
  component: Layout,
  name: 'Requirements',
  redirect: {
    name: 'RequirementsAppVersionManage'
  },
  meta: {
    title: '需求管理',
    icon: 's-management'
  },
  children: [
    {
      path: 'app-version-manage',
      name: 'RequirementsAppVersionManage',
      component: () =>
        import(/* webpackChunkName: "app-version-manage" */ '@/pages/requirements/app-version-manage/index.vue'),
      meta: {
        keepAlive: true,
        needAuth: true,
        title: 'APP版本需求',
        icon: 'apple'
      }
    }
  ]
}

export default record
