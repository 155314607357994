<template>
  <div class="fen-input">
    <el-input type="number" v-model.number="value"></el-input>
  </div>
</template>
<script>
export default {
  name: 'FenInput',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Number,
      default: 0
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue / 100
      },
      set(val) {
        this.$emit('update:modelValue', val * 100)
      }
    }
  }
}
</script>
