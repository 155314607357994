// 见识号的相关数据
import { Module } from 'vuex'
import { RootState } from '@/store'

export interface ErrorModuleState {
  list: {}[]
}

const state = {
  list: []
}

const errorModule: Module<ErrorModuleState, RootState> = {
  namespaced: true,
  state,
  actions: {
    dispatch_common({ commit, state }) {
      //
    }
  },
  mutations: {
    update_common(state, payload: Partial<ErrorModuleState>) {
      console.log('update-common', payload)
      Object.assign(state, payload)
    }
  }
}
export default errorModule
