import { userStore } from '@/store/modules/user'
import type { Router } from 'vue-router'
import createProgressGuard from './progressGuard'
import createPermissionGuard from './permissionGuard'

const whiteNames: Array<string | symbol | null | undefined> = ['Login', 'NotFound', 'NotAuthed']

export default function crateGuarde(router: Router) {
  router.beforeEach((to, from, next) => {
    if (!userStore.getToken && !whiteNames.includes(to.name)) {
      next({
        name: 'Login'
      })
    } else {
      next()
    }
  })
  createProgressGuard(router)
  createPermissionGuard(router)
}
