import xgb from '@/http/xgb'

export * from './xgb-user'
export * from './xgb-tag'

// 七牛token相关
export const getQiniuToken = () => xgb.post('/api/admin/file/upload_token?reuse=true')
export const getPremiumToken = () => xgb.post('/api/admin/file/premium_upload_token?reuse=true')
export const uploadToQiniu = (data) => xgb.post('https://upload.qiniup.com', data)
export const getWatermark = (data) => xgb.post('/api/admin/file/fetch', data)

// xgb搜股票
export const XGBsearchStocks = (params) => xgb.get('/api/search/stock', { params })
