import wscn from '@/http/wscn'
import type { UserInfo, LoginResult } from './models/user'

/**
 * @description 发送验证码
 * @date 2020-12-02
 * @export
 * @param {string} email
 * @returns
 */
export function sendCaptcha(email: string) {
  return wscn.get('/apiv1/message/internal_email/send', { params: { email } })
}

/**
 * @description 邮箱登录
 * @date 2020-12-02
 * @export
 * @param {{ email: string; password: string; captcha: string }} data
 * @returns {Promise<LoginResult>}
 */
export function login(data: { email: string; password: string; captcha: string }): Promise<LoginResult> {
  return wscn.post('/apiv1/admin/user/internal/signin/password', data, {
    headers: {
      'X-Ivanka-Token': ''
    }
  })
}

/**
 * @description 刷新用户信息
 * @date 2020-12-02
 * @export
 * @returns {Promise<UserInfo>}
 */
export function getUserInfo(): Promise<UserInfo> {
  return wscn.get('/apiv1/admin/user/internal/info')
}

export function searchInternalUser(params): Promise<any> {
  return wscn.get('/apiv1/admin/user/internal/info/list', { params })
}