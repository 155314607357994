import xgb from '@/http/xgb'

export const searchxgbTag = params => xgb.get('/api/admin/search/tag', { params })
export const getxgbTagList = params => xgb.get('/api/admin/tags/list', { params })
export const getxgbTagById = id => xgb.get(`/api/admin/tags/${id}`)
export const getTagSubjects = id => xgb.get(`/api/admin/tags/${id}/subjects`)
export const updateTagSubjects = (id, data) => xgb.put(`/api/admin/tags/${id}/subjects`, data)
export const deletexgbTag = id => xgb.delete(`/api/admin/tags/${id}`)
export const addxgbTag = data => xgb.post('/api/admin/tags', data)
export const updatexgbTag = (id, data) => xgb.put(`/api/admin/tags/${id}`, data)

