export class Event {
  private store: {}
  private storeOnce: {}
  private cached: {}
  constructor() {
    this.store = {}
    this.storeOnce = {}
    this.cached = {}
  }
  public $on(name, func, id = '', useCached = false) {
    if (this.store[name]) {
      if (id === '' || !this.store[name].some((v) => v.id === id)) {
        this.store[name].push({ func, id })
      } else {
        this.store[name].forEach((ev, idx) => {
          if (ev.id === id) {
            this.store[name][idx] = {
              id: ev.id,
              func,
            }
          }
        })
      }
    } else {
      this.store[name] = [{ func, id }]
    }
    if (this.cached[name] instanceof Array && useCached) {
      func(...this.cached[name])
    }
  }
  public $off(name) {
    this.store[name] = null
    this.cached[name] = null
  }
  public $once(name, func, id = '') {
    console.log('event_hub_log:', this.storeOnce[name], this.cached[name])
    if (this.storeOnce[name]) {
      if (id !== '' && !this.storeOnce[name].some((v) => v.id === id)) {
        this.storeOnce[name].push({ func, id })
      }
    } else {
      this.storeOnce[name] = [{ func, id }]
    }
    if (this.cached[name] instanceof Array) {
      func(...this.cached[name])
      this.cached[name] = null
    }
  }
  public $emit(name, ...param) {
    if (this.store[name] instanceof Array) {
      // 遍历该事件的所有函数 传入数据执行
      this.store[name].forEach((event) => event.func(...param))
      // 同时往缓存里面添加事件 事件名称: data
      this.cached[name] = param
    } else if (this.storeOnce[name] instanceof Array) {
      // store 没有 storeOnce 里面有则走这里
      this.storeOnce[name].forEach((event) => event.func(...param))
      this.storeOnce[name] = null
    } else {
      // 否则把参数塞进缓存池 缓存池时覆盖的 采用时间维度上最新的
      this.cached[name] = param
    }
  }
}

export default new Event()