import store from '@/store'
import dayjs from 'dayjs'

export const getEnv = (): string => process.env.VUE_APP_ENV || 'local'

export const isProd = getEnv() === 'prod'

export const isType = type => {
  return target => {
    return Object.prototype.toString.call(target) === `[object ${type}]`
  }
}

export function formatStockRate(rate = 0) {
  if (typeof rate !== 'number') return rate
  if (rate > 0) {
    return `+${rate.toFixed(2)}%`
  } else if (rate < 0) {
    return `${rate.toFixed(2)}%`
  }
  return '0.00%'
}

export function hotModuleUnregisterModule(name: string) {
  if (!name) return
  if ((store.state as any)[name]) {
    store.unregisterModule(name)
  }
}

export function formatDate(time: number | Date, format = 'YYYY/MM/DD'): string {
  if (!time) return ''
  if (!isNaN(parseFloat((time as unknown) as string)) && time < 10e9) ((time as unknown) as number) *= 1000
  return dayjs(time).format(format)
}

export function emptyFun<T>(thing: T): T {
  return thing
}

const timerMap = {}
export function intervalTimer(cb: (...any) => void, inter: number, now = false) {
  const mark = Math.random().toString(36).slice(8)
  if (now) cb()
  const temp = function () {
    cb && cb()
    clearTimeout(timerMap[mark])
    timerMap[mark] = setTimeout(temp, inter)
  }
  timerMap[mark] = setTimeout(temp, inter)
  return () => clearTimeout(timerMap[mark])
}

export function replaceHighLightWords(text, words) {
  if (typeof words === 'string') {
    words = [words]
  }
  if (!words || !words.length) return text
  const fragement = document.createElement('div')
  fragement.innerHTML = text

  function travelReplaceText(node) {
    if (node && node.nodeType === Node.ELEMENT_NODE) {
      node.childNodes.forEach(cnode => travelReplaceText(cnode))
    } else if (node && node.nodeType === Node.TEXT_NODE) {
      node.nodeValue = node.nodeValue.replace(
        new RegExp(`(${words.map(i => `(${i})`).join('|')})`, 'ig'),
        '<em class="es_highlight">$1</em>'
      )
    }
  }
  travelReplaceText(fragement)
  return fragement.innerHTML.replace(
    /&lt;em class="es_highlight"&gt;([\s\S]*?)&lt;\/em&gt;/g,
    '<em class="es_highlight">$1</em>'
  )
}

// function replaceNode(node) {
//   if (!node) return
//   if (node.nodeType === Node.ELEMENT_NODE) {
//     node.childNodes.forEach(cnode => replaceNode(cnode))
//   } else if (node.nodeType === Node.TEXT_NODE) {
//     const span = document.createElement('span')
//     span.innerHTML = node.nodeValue
//     node.parentNode.replaceChild(span, node)
//   }
// }

export function getChromeVersion() {
  const arr = navigator.userAgent.split(' ')
  let chromeVersion = ''
  for (let i = 0; i < arr.length; i++) {
    if (/chrome/i.test(arr[i]))
      chromeVersion = arr[i]
  }
  if (chromeVersion) {
    return Number(chromeVersion.split('/')[1].split('.')[0])
  } else {
    return false
  }
}

export * from './validate'
export * from './auth'
export * from './create-async'
export { default as eventHub } from './event_hub'
