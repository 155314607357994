import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import config from '@/config'
import { userStore } from '@/store/modules/user'
import AxiosClient from '../base/AxiosClient'
// import { throwApiErr } from './err'

function requestConfig(requestConfig: AxiosRequestConfig): AxiosRequestConfig {
  const conf: AxiosRequestConfig = {
    ...requestConfig,
    headers: {
      'X-Ivanka-Token': userStore?.getToken,
      ...requestConfig.headers
    }
  }
  return conf
}
function handleError(res) {
  if (res.errmsg == '其他客户端登录了') {
    userStore.dispatchLogout()
  }
}

function handleBusinessError(response: AxiosResponse) {
  // xgb下的接口 有errcode则说明业务出错
  if (response.data.errcode) {
    handleError(response.data)
    // 保持与其他的域名的错误结构一致
    const err_data = {
      code: response.data.errcode,
      url: response.config.url,
      message: response.data.errmsg
    }
    console.log('XGB:', new Error(JSON.stringify(err_data, null, 2)))
    return Promise.reject(err_data)
  }
  return response.data
}

class XGBServiceClient extends AxiosClient {
  constructor(options: AxiosRequestConfig) {
    super(options)
    this.interceptRequest()
    this.interceptResponse()
  }
  interceptRequest() {
    this.client.interceptors.request.use(requestConfig, Promise.reject)
  }
  interceptResponse() {
    this.client.interceptors.response.use(handleBusinessError, Promise.reject)
  }
}

export default new XGBServiceClient({
  baseURL: config.apiBase.BASE_API
})
