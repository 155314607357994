import type { RouteRecordRaw } from 'vue-router'
import Layout from '@/Layout/index.vue'
import RouterView from '@/Layout/router-view.vue'
// import { defineAsyncComponent } from 'vue'
// import { loadAsyncComponent } from '@/utils'

const record: RouteRecordRaw = {
  name: 'Stats',
  path: '/stats',
  component: Layout,
  redirect: {
    name: 'Dashboard'
  },
  meta: {
    title: '统计面板',
    icon: 'odometer'
  },
  children: [
    {
      path: 'dash',
      name: 'Dashboard',
      component: RouterView,
      meta: {
        keepAlive: false,
        title: '数据',
        icon: 'aim'
      },
      children: [
        {
          path: 'stat-bu',
          name: 'StatBu',
          meta: {
            keepAlive: false,
            // needAuth: true,
            title: '业务线',
            icon: 'watch'
          },
          component: () => import(/* webpackChunkName: "stat-bu" */ '@/pages/stats/dashboard/stat-bu.vue')
        },
        {
          path: 'stat-plat',
          name: 'StatPlat',
          meta: {
            keepAlive: false,
            // needAuth: true,
            icon: 'place',
            title: '平台'
          },
          component: () => import(/* webpackChunkName: "stat-plat" */ '@/pages/stats/dashboard/stat-plat.vue')
        },
        {
          path: 'flash-push',
          name: 'FlashPush',
          meta: {
            keepAlive: false,
            needAuth: false,
            icon: 'message-solid',
            title: '快讯'
          },
          component: () => import(/* webpackChunkName: "flash-push" */ '@/pages/stats/flash-push/index.vue')
        }
      ]
    },
    {
      path: 'stat-user',
      name: 'StatUser',
      meta: {
        keepAlive: false,
        title: '社区',
        icon: 's-custom'
      },
      redirect: { name: 'UserGenerate' },
      component: RouterView,
      children: [
        {
          path: 'generate',
          name: 'UserGenerate',
          component: () => import(/* webpackChunkName: "user-generate" */ '@/pages/stats/user/user-generate.vue'),
          meta: {
            keepAlive: false,
            // needAuth: true,
            title: '内容生产',
            icon: 'ice-tea'
          }
        },
        {
          path: 'reading',
          name: 'UserReading',
          component: () => import(/* webpackChunkName: "user-reading" */ '@/pages/stats/user/user-reading.vue'),
          meta: {
            keepAlive: false,
            // needAuth: true,
            title: '内容阅读',
            icon: 'ice-drink'
          }
        },
        {
          path: 'interact',
          name: 'UserInteract',
          component: () => import(/* webpackChunkName: "user-interact" */ '@/pages/stats/user/user-interact.vue'),
          meta: {
            keepAlive: false,
            // needAuth: true,
            title: '用户互动',
            icon: 'milk-tea'
          }
        }
      ]
    }
  ]
}

if (process.env.VUE_APP_ENV === 'local') {
  record.children?.push({
    path: 'test',
    name: 'Test',
    component: () => import('@/pages/test/index.vue'),
    // component: () => loadAsyncComponent(import('@/pages/test/index.vue')),
    meta: {
      keepAlive: false,
      title: '测试',
      icon: 'position'
    }
  })
}

export default record
