
import { defineComponent } from 'vue'
import SideBar from './SideBar.vue'
import TopPanel from './topPanel/index.vue'

export default defineComponent({
  components: {
    SideBar,
    TopPanel
  }
})
