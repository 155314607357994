import { ElMessage } from 'element-plus'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import type { UserInfo, LoginResult } from '@/domains/user/models/user'
import store from '@/store'
import router from '@/router'
import { hotModuleUnregisterModule } from '@/utils'
import { setCookieToken, getCookieToken } from '@/utils/auth'
import { getUserInfo, login, sendCaptcha } from '@/domains/user/api'

hotModuleUnregisterModule('user')

@Module({
  namespaced: true,
  name: 'user',
  dynamic: true,
  store
})
export default class User extends VuexModule {
  private userInfo: UserInfo | null = null
  private token = getCookieToken()
  
  get getUserInfo(): UserInfo | null {
    return this.userInfo
  }

  get getToken(): string {
    return this.token
  }

  @Mutation
  resetState(): void {
    this.userInfo = null
    this.token = ''
    setCookieToken('')
  }

  @Mutation
  commitUserInfo(info: UserInfo | null): void {
    this.userInfo = info
  }

  @Mutation
  commitToken(token: string): void {
    this.token = token
    setCookieToken(token)
  }

  @Action
  async dispatchSendCaptcha(email: string) {
    await sendCaptcha(email)
  }

  @Action
  async dispatchLogin(loginParams: { email: string; password: string; captcha: string }): Promise<LoginResult | null> {
    try {
      const tokenPayload: LoginResult = await login(loginParams)
      if (tokenPayload && tokenPayload.token) {
        this.commitToken(tokenPayload.token)
        this.dispatchGetUserInfo()
        return tokenPayload
      }
      return null
    } catch (error) {
      ElMessage.error(error.message)
      return null
    }
  }

  @Action({ commit: 'commitUserInfo' })
  async dispatchGetUserInfo() {
    if (!this.token) return
    try {
      const user: UserInfo = await getUserInfo()
      return user
    } catch (error) {
      ElMessage.error(error.message)
    }
  }

  @Action({ commit: 'resetState' })
  dispatchLogout(): void {
    router.replace({ name: 'Login' })
  }
}

export const userStore = getModule<User>(User)
