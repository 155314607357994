// 见识号的相关数据
import { Module } from 'vuex'
import { RootState } from '@/store'

interface ListItem {
  id: number
  title: string
}
export interface WitsModuleState {
  list: ListItem[]
  is_ok: boolean
}

const state = {
  list: [],
  is_ok: false
}

const witsModule: Module<WitsModuleState, RootState> = {
  namespaced: true,
  state,
  actions: {
    dispatch_common({ commit, state }) {
      console.log('dispatch-common')
      setTimeout(() => {
        commit('update_common', { is_ok: !state.is_ok })
      })
    }
  },
  mutations: {
    update_common(state, payload: Partial<WitsModuleState>) {
      console.log('update-common', payload)
      Object.assign(state, payload)
    }
  }
}
export default witsModule
