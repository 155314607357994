import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import App from '@/App.vue'
import router, { setupRouter } from '@/router'
import { setupStore } from '@/store'
import { setupPlugins } from '@/plugins'
import { ConfigMap } from '@/config'
import '@/styles'

import { userStore } from '@/store/modules/user'
declare module '@vue/runtime-core' {
  interface AppConfig {
    devtools: boolean
  }
  interface ComponentCustomProperties {
    $runtimeConfig: ConfigMap
  }
}

const app = createApp(App)

setupStore(app)
setupRouter(app)
setupPlugins(app)
app.use(ElementPlus, { size: 'small' })

app.config.devtools = process.env.NODE_ENV === 'development'
router.isReady().then(() => {
  app.mount('#app')
})

userStore.dispatchGetUserInfo()
