import type { RouteRecordRaw } from 'vue-router'
import Layout from '@/Layout/index.vue'
import { isProd } from '@/utils'

const record: RouteRecordRaw = {
  name: 'settings',
  path: '/settings',
  component: Layout,
  redirect: {
    name: 'OpenAccount'
  },
  meta: {
    title: 'APP配置',
    icon: 'setting'
  },
  children: [
    {
      path: 'open-account',
      name: 'OpenAccount',
      component: () => import(/* webpackChunkName: "open-account" */ '@/pages/settings/open-account/index.vue'),
      meta: {
        title: '开户页',
        needAuth: isProd && true,
        icon: 'bank-card'
      }
    },
    {
      path: 'route-icon',
      name: 'RouteIcon',
      component: () => import(/* webpackChunkName: "route-icon" */ '@/pages/settings/route-icon/index.vue'),
      meta: {
        title: '路由icon',
        needAuth: isProd && true,
        icon: 'link'
      }
    },
    {
      path: 'discover-tab',
      name: 'DiscoverTab',
      component: () => import(/* webpackChunkName: "discover-tab" */ '@/pages/settings/discover-tab/index.vue'),
      meta: {
        title: '发现tab',
        needAuth: isProd && true,
        icon: 'grape'
      }
    },
    {
      path: 'category-tab',
      name: 'CategoryTab',
      component: () => import(/* webpackChunkName: "category-tab" */ '@/pages/settings/category-tab/index.vue'),
      meta: {
        title: '关注分类',
        needAuth: isProd && true,
        icon: 'watermelon'
      }
    },
    {
      path: 'app-conf',
      name: 'AppConf',
      component: () => import(/* webpackChunkName: "app-conf" */ '@/pages/settings/app-conf/index.vue'),
      meta: {
        title: '客户端',
        needAuth: isProd && true,
        icon: 'open'
      }
    },
    {
      path: 'kv-config',
      name: 'KVConfig',
      component: () => import(/* webpackChunkName: "kv-config" */ '@/pages/settings/kv-config/list.vue'),
      meta: {
        title: 'KVconfig',
        needAuth: isProd && true,
        icon: 'cpu'
      }
    },
    {
      path: 'kv-edit/:id',
      name: 'KVEdit',
      component: () => import(/* webpackChunkName: "kv-edit" */ '@/pages/settings/kv-config/create.vue'),
      meta: {
        removeFromSideBar: true,
        needAuth: isProd && true,
        title: '编辑KV'
      }
    },
    {
      path: 'kv-create',
      name: 'KVCreate',
      component: () => import(/* webpackChunkName: "kv-create" */ '@/pages/settings/kv-config/create.vue'),
      meta: {
        removeFromSideBar: true,
        needAuth: isProd && true,
        title: '创建KV'
      }
    }
  ]
}

export default record
