import runtimeConfigI from '@/config'
import { formatDate, formatStockRate } from '@/utils'
export { isType } from '@/utils'

export const runtimeConfig = runtimeConfigI
export const filters = {
  formatDate,
  formatStockRate,
  toYuan(value) {
    return ((value || 0) / 100).toFixed(2)
  }
}