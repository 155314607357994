import { getEnv } from '@/utils'
import localEnv from './local.config'
import DevEnv from './dev.config'
import ProdEnv from './prod.config'
import FitEnv from './fit.config'
import SitEnv from './sit.config'

export type ENVS = 'local' | 'dev' | 'fit' | 'prod' | 'sit'

export type ConfigMap = {
  env: ENVS
  apiBase: {
    BASE_API: string
    DDC_API: string
    STARK_API: string
    WSCN_API: string
    JIRA_API: string
    QINIU_DOMAIN: string
    BAOER_API: string
    FLASH_API: string
  }
  siteDomain: {
    PC_DOMAIN: string
    M_DOMAIN: string
    ADMIN_DOMAIN: string
    JIRA_DOMAIN: string
  }
}

type ENVCONFIG = {
  [params in ENVS]: Record<string, any>
}

export default (({
  local: localEnv,
  dev: DevEnv,
  fit: FitEnv,
  prod: ProdEnv,
  sit: SitEnv
} as ENVCONFIG)[getEnv() as ENVS] || localEnv) as ConfigMap
